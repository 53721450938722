<template>
    <div>
        <div class="modal fade" id="driverModal" tabindex="-1" aria-labelledby="driverModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="driverModalLabel" class="font-weight-bold">{{ModalTitle}}{{DriverCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Kode</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="DriverCode" v-model="DriverCode" class="font-weight-bold" />
                                    <label id="errorDriverCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>               
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="DriverName" v-model="DriverName" class="font-weight-bold" />
                                    <label id="errorDriverName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Handphone 1</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="HandPhone1" v-model="HandPhone1" class="font-weight-bold" />
                                    <label id="errorHandPhone1" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Handphone 2</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="HandPhone2" v-model="HandPhone2" class="font-weight-bold" />
                                    <label id="errorHandPhone2" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Note</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Note" v-model="Note" class="font-weight-bold" />
                                    <label id="errorNote" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>  
                            </CRow>
                        
                            <br>
                            <label id="errorDriverGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import driverServices from '../Script/DriverServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'DriverForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            SaveType : '',
            Error: 0,

            DriverId : '',
            DriverCode : '',
            DriverName : '',
            Status : '',
            StatusData: [],
            HandPhone1 : '',
            HandPhone2 : '',
            Note : '',
        }
    },
    methods: {
        addClick(){   
            $(".form-error").css("display", "none");  
            this.ModalTitle = 'Add Driver: ';

            this.DriverCode = 'Auto';
            this.DriverName = '';
            this.StatusData = [{value: 'N', label: 'New'}];
            this.Status = this.StatusData[0].value;
            this.HandPhone1 = '';
            this.HandPhone2 = '';
            this.Note = '';

            this.SaveType = 'Add';
            $('#tab-1').trigger('click');
            document.getElementById('DriverCode').readOnly = false;
            window.$('#driverModal').modal('show');
        },
        async editClick(id, View) {
            var data = await driverServices.getDetailContact(id);

            $(".form-error").css("display", "none");
            $(".save-button").show(); 

            this.ModalTitle = 'Edit Driver: ';
            this.DriverId = data.contact_id;
            this.DriverCode = data.contact_code;
            this.DriverName = data.contact_name;
            
            this.StatusData = [ {value: 'A', label: 'Active'},
                                {value: 'I', label: 'Inactive'}];
            this.Status = data.status=="N"?"A":data.status;
            
            
            var driverData = data.metadata.driverdetail;
            this.HandPhone1 = driverData.handphone1;
            this.HandPhone2 = driverData.handphone2;
            this.Note = driverData.note;
        
            this.SaveType = 'Edit';
            $('#tab-1').trigger('click');
            document.getElementById('DriverCode').readOnly = true;

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Driver: ';
            }

            window.$('#driverModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.DriverCode == ''){
                this.errorShow('errorDriverCode');
            }
            if(this.DriverName == ''){
                this.errorShow('errorDriverName');
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                const driverdetail = {
                    handphone1 : this.HandPhone1,
                    handphone2 : this.HandPhone2,
                    note : this.Note
                }

                const metadata = {
                    driverdetail : driverdetail
                }

                const driver = {
                    contact_code : this.DriverCode,
                    contact_type : "Driver",
                    contact_name : this.DriverName,
                    status : this.Status,
                    metadata : metadata,
                    attachment1: null,
                    attachment2: null,
                }
                
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : driver
                    }

                    driverServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#driverModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.DriverId,
                        data : driver
                    }

                    driverServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#driverModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        onChange(){
            let cityData = this.ProvinceCity.find(data => data.provinsi === this.Province);
            this.CityData = [];
            for (let i = 0; i < cityData.kota.length; i++) {
                var str = {value:cityData.kota[i], label:cityData.kota[i]}
                this.CityData.push(str);
            }
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
    }
}
</script>
